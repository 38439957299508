var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// components
import FormProvider from '../../components/hook-form/FormProvider';
import RHFTextField from '../../components/hook-form/RHFTextField';
import Iconify from '../../components/iconify';
import { getGeoIp } from '../../utils';
export default function AuthLoginForm() {
    var _this = this;
    var login = useAuthContext().login;
    var _a = useState(false), showPassword = _a[0], setShowPassword = _a[1];
    var LoginSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required'),
    });
    var defaultValues = {
        email: '',
        password: '',
    };
    var methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues: defaultValues,
    });
    var setError = methods.setError, handleSubmit = methods.handleSubmit, _b = methods.formState, errors = _b.errors, isSubmitting = _b.isSubmitting, isSubmitSuccessful = _b.isSubmitSuccessful;
    var onSubmit = function (data) { return __awaiter(_this, void 0, void 0, function () {
        var geoIp, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, getGeoIp()];
                case 1:
                    geoIp = _a.sent();
                    return [4 /*yield*/, login(data.email, data.password, (geoIp === null || geoIp === void 0 ? void 0 : geoIp.ip) || null)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    setError('afterSubmit', __assign(__assign({}, error_1), { message: (error_1 === null || error_1 === void 0 ? void 0 : error_1.message) || 'invalid credential' }));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(FormProvider, __assign({ methods: methods, onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs(Stack, __assign({ spacing: 4, sx: { m: 2, p: 2 } }, { children: [!!errors.afterSubmit && _jsx(Alert, __assign({ severity: "error" }, { children: errors.afterSubmit.message })), _jsx(RHFTextField, { name: "email", label: "Email address" }), _jsx(RHFTextField, { name: "password", label: "Password", type: showPassword ? 'text' : 'password', InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ onClick: function () { return setShowPassword(!showPassword); }, edge: "end" }, { children: _jsx(Iconify, { icon: showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill' }) })) }))),
                        } })] })), _jsx(LoadingButton, __assign({ fullWidth: true, color: "inherit", type: "submit", variant: "contained", loading: isSubmitSuccessful || isSubmitting, sx: {
                    bgcolor: 'text.primary',
                    color: function (theme) { return (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'); },
                    '&:hover': {
                        bgcolor: 'text.primary',
                        color: function (theme) { return (theme.palette.mode === 'light' ? 'common.white' : 'grey.800'); },
                    },
                    m: '1rem 2.5rem',
                    width: '80%',
                } }, { children: "Login" }))] })));
}
